import 'bootstrap'

window.addEventListener("DOMContentLoaded", (event) => {
  $.fn.datetimepicker.dates['fr-FR'] = {
    days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
    daysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    daysMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthsShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sept", "Oct", "Nov", "Déc"],
    today: "Aujourd'hui"
  };

  $('.js-datetimepicker').datetimepicker({
    language: 'fr-FR'
  });

  $('img.lazy').lazyload();

  $('.social-sharer[data-sharer="copy-url"]').on('click', function(event) {
    event.preventDefault();
    navigator.clipboard.writeText($(this).data('url'));
  });

  if(window.Sharer) {
    window.Sharer.init();
  }

  $('a[href="#search"]').on('click', function(event) {
      event.preventDefault();
      $('#js-search').addClass('open');
      $('#js-search > form > input[type="text"]').focus();
  });

  $('#js-search, #js-search button.close').on('click keyup', function(event) {
    if (event.target == this || event.target.className == 'close' || event.target.className == 'fas fa-times' || event.keyCode == 27) {
      $(this).removeClass('open');
    }
  });

  if($(window).width() <= 767) {
    $('.js-submenu').detach().appendTo('.navbar.js-header-navbar');
    $('.js-submenu').removeClass('d-none');
  }

  $('.js-manage-cookies').on('click', function(event) {
    event.preventDefault();
    Didomi.preferences.show();
  });

  $('.js-sibling-item').on('click', function(event) {
    event.preventDefault();

    var link = $(event.currentTarget).parent().find("a.js-original-link");
    var targetUrl = link.attr('href');
    window.location.href = targetUrl;
  });

  $('.js-sibling-link').on('click', function(event) {
    event.preventDefault();

    var targetDiv = $(event.currentTarget);

    if(targetDiv.hasClass('js-sibling-link')) {
      var nextElement = targetDiv.next();

      if(nextElement.prop('nodeName') == "A") {
        window.location.href = nextElement.prop('href');
      }
    }
  });

  /*
    Carousel
  */
  $('#vdn-news-carousel').on('slide.bs.carousel', function (e) {
    var e = $(e.relatedTarget);
    var idx = e.index();var itemsPerSlide = 4;
    var totalItems = $("#vdn-news-carousel .carousel-item").length;

    if (idx >= totalItems-(itemsPerSlide-1)) {
      var it = itemsPerSlide - (totalItems - idx);
      for (var i=0; i<it; i++) {
        // append slides to end
        if (e.direction=="left") {
          $('#vdn-news-carousel .carousel-item').eq(i).appendTo('#vdn-news-carousel .carousel-inner');
        } else {
          $('#vdn-news-carousel .carousel-item').eq(0).appendTo('#vdn-news-carousel .carousel-inner');
        }
      }
    }
  });

  $('#vdn-news-carousel').carousel({ interval: false });

  $('#vdn-news-carousel').on('slid.bs.carousel', function (e) {
    $('#vdn-news-carousel .carousel-item:visible').removeClass('border-0');
    $('#vdn-news-carousel .carousel-item:visible:last').addClass('border-0');
  });

  $('.js-obs-items').each(function(index) {
    var text      = $(this).text();
    var cssKlass  = $(this).data('css');
    var hexUrl    = $(this).data('target');
    var hexString = hexUrl.toString();
    var url       = '';

    for(var n = 0; n < hexString.length; n += 2) {
      url += String.fromCharCode(parseInt(hexString.substr(n, 2), 16));
    }

    $(this).replaceWith("<a href='" + url + "' class='" + cssKlass + " js-obs-replaced'>" + text + "</a>");
  });

  $('.js-iframe-post').each(function(index) {
    if($(this).find('iframe').length > 0) {
      var iframe = $(this).find('iframe');
      var url = iframe.attr('src');

      if(url.match('ultimedia.com')) {
        $(this).addClass('didomi-video');
      }
    }
  });

  $('.js-block-content-kind').each(function(i) {
    var contentKind = $(this).val();
    var parentDiv = $(this).parents('.nested-fields');
    var target = $(parentDiv).find(".js-" + contentKind + "-content");
    target.removeClass('d-none');
  });

  $('.js-block-content-kind').on('change', function(e) {
    var contentKind = $(this).val();
    var parentDiv = $(this).parents('.nested-fields');

    $(parentDiv).find(".js-text-content").addClass('d-none');
    $(parentDiv).find(".js-iframe-content").addClass('d-none');
    $(parentDiv).find(".js-image-content").addClass('d-none');
    $(parentDiv).find(".js-button-content").addClass('d-none');

    var target = $(parentDiv).find(".js-" + contentKind + "-content");
    target.removeClass('d-none');
  });

  $(".owl-carousel").owlCarousel({
    loop: true,
    nav: true,
    navText: ['&#8592;','&#8594;'],
    responsiveClass: true,
    responsive:{
      0:{
        items:1,
        dots: false,
        nav: false
      },
      600:{
        items:3,
        dots: false,
        nav: false
      },
      800:{
        items:4,
        dots: false,
        nav: false
      },
      1025:{
        items:6,
        dots: false
      }
    }
  });

  // PWA popup for iOS
  var userAgent = window.navigator.userAgent.toLowerCase();
  var isIos = /iphone|ipad|ipod/.test(userAgent);

  // Detects if device is in standalone mode
  var isInStandaloneMode = false;
  if(('standalone' in window.navigator) && (window.navigator.standalone)) {
    isInStandaloneMode = true;
  }

  var pwaPopupCookie = document.cookie.split('; ').find(row => row.startsWith('pwaPopup='));

  // Checks if should display install popup notification:
  if (isIos && !isInStandaloneMode && !pwaPopupCookie) {
    var modal = document.getElementById("pwaModal");
    var span = document.getElementsByClassName("close")[0];

    if(span) {
      modal.style.display = "block";

      span.onclick = function() {
        modal.style.display = "none";

        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 14*24*60*60*1000;
        now.setTime(expireTime);

        document.cookie = 'pwaPopup=true; expires='+now.toUTCString()+'; path=/';
      }
    }
  } else {
    console.log('no homescreen btn')
  }

  $('.js-captioned').each(function(i) {
    if($(this).data('caption') != "" && $(this).data('caption') != undefined) {
      $(this).siblings('figcaption').css('left', this.offsetLeft + "px").css('width', this.width + "px");
    }
  });

  window.addEventListener('appinstalled', () => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 2*365*24*60*60*1000;
    now.setTime(expireTime);

    document.cookie = 'pwaPopup=true; expires='+now.toUTCString()+'; path=/';
  });

  $('.js-clear-fields').on('click', function(e) {
    $(this).parents('.nested-fields:not(.nested-fields-0)').addClass('d-none');
    $(this).parents('.nested-fields').find("input[type=text], input[type=url]").val('');
    e.preventDefault();
  });
  $('.js-add-fields').on('click', function(e) {
    e.preventDefault();
    $('.nested-fields.d-none').first().removeClass('d-none');
  });

  // Get the header
  var header = document.getElementById("main-header");

  if(header) {
    window.onscroll = function() { stickyHeader(); };

    // Get the offset position of the navbar
    var sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function stickyHeader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }

  if($(".trendy-topics-list").length) {
    var topicsList = $(".trendy-topics-list");
    var nextArrow = topicsList.siblings('span.nav');

    if(nextArrow.length && topicsList[0].scrollWidth > topicsList.width()) {
      nextArrow.removeClass('d-none');

      nextArrow.on('click', function() {
        var x;
        var diff = topicsList[0].scrollWidth - topicsList.width();

        if(topicsList.scrollLeft() < diff) {
          var scroll = topicsList.scrollLeft() + 80;
          if(scroll > diff) {
            scroll = diff;
          }
          topicsList.animate({ scrollLeft: scroll }, 300);
        }
      });
    }
  }

  $('.nav-group-label').on('click', function() {
    $(this).siblings('.subitems-nav').toggleClass('d-none');

    if($(this).find('i.fa-chevron-up').length) {
      $(this).find('i.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    } else {
      $(this).find('i.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-up');
    }
  });
});
