window.addEventListener("DOMContentLoaded", (event) => {
  $('.js-obf').on('click', function() {
    var url = atob($(this).data('road'));

    if($(this).data('target') == '_blank') {
      window.open(url, '_blank').focus();
    } else {
      window.location = url;
    }
  });
});
