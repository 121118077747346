class CharacterCounter {
  constructor(cssClass) {
    this.cssClass = cssClass;
  }

  buildInfos = function(counter, min) {
    var cssKlass = 'seo-green';
    var textContent = '';

    if(counter > 1) {
      textContent = counter + " caractères";
    } else {
      textContent = counter + " caractère";
    }

    if(counter < min) {
      cssKlass = 'seo-red';
    }
    return "<small class='js-char-counter-info py-1 " + cssKlass + "'>" + textContent + "</small>"
  }


  updateCharacterCounter() {
    var self = this;
    if($(this.cssClass).length) {
      $(this.cssClass).each(function(index) {
        var input = $(this);
        var min = input.data('min-char');

        $(input).on('keyup', function() {
          var counter = $(input).text().length;
          var message = self.buildInfos(counter, min);

          $(input).siblings('.js-char-counter-info').replaceWith(message)
        });
      });
    }
  }

  addCharCounterToTextarea() {
    var self = this;
    if($(this.cssClass).length) {
      $(this.cssClass).each(function (index) {
        var input = $(this);
        if (!input.next().hasClass('js-char-counter-info')) {
          var min = input.data('min-char');
          var counter     = $(input).text().length;
          var message = self.buildInfos(counter, min);

          $(message).insertAfter($(input));
        }
      });
    }
  }
}

window.addEventListener("DOMContentLoaded", (event) => {
  var counter = new CharacterCounter('.js-char-counter');
  counter.addCharCounterToTextarea();
  counter.updateCharacterCounter();
});

export { CharacterCounter }
