var updateCounter = function(cssClass, item, min, max) {
  if($(cssClass).length) {
    $(cssClass).each(function(index) {
      var input = $(this).find(item);

      $(input).on('keyup', function() {
        var counter     = $(input).val().length;
        var cssKlass    = 'seo-green';
        var textContent = '';

        if(counter > 1) {
          textContent = counter + " caractères";
        } else {
          textContent = counter + " caractère";
        }

        if(counter < min || counter > max) {
          cssKlass = 'seo-red';
        }

        $(input).siblings('.js-seo-counter').replaceWith("<small class='js-seo-counter py-1 " + cssKlass + "'>" + textContent + "</small>")
      });
    });
  }
}

var addCounterToInput = function(cssClass, item, min, max) {
  if($(cssClass).length) {
    $(cssClass).each(function(index) {
      var input       = $(this).find(item);
      var counter     = $(input).val().length;
      var cssKlass    = 'seo-green';
      var textContent = '';

      if(counter < min || counter > max) {
        cssKlass = 'seo-red';
      }

      if(counter > 1) {
        textContent = counter + " caractères";
      } else {
        textContent = counter + " caractère";
      }

      $("<small class='js-seo-counter py-1 " + cssKlass + "'>" + textContent + "</small>").insertAfter($(input));
    });
  }
}

window.addEventListener("DOMContentLoaded", (event) => {
  addCounterToInput('.js-seo-counter-title', 'input[type=text]', 40, 65);
  addCounterToInput('.js-seo-counter-description', 'textarea', 100, 155);

  updateCounter('.js-seo-counter-description', 'textarea', 100, 155);
  updateCounter('.js-seo-counter-title', 'input[type=text]', 40, 65);
});
